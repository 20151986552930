import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Website Design Agency`,
    text: "Creative Website Design",
    content: "We are a top website design agency, delivering custom website design services to drive tangible results for your business. Our talented website designers work closely with companies to craft visually stunning, responsive websites tailored to their unique needs.",
    list: [
      'Seasoned Design Expertise',
      'Custom Website Approach',
      'Responsive Website Solutions'
    ]
  };
  const ourData = {
    title: `Hear From Clients About Our Website Design Services`,
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Drive Success With Our Website Design Company",
    content: [
      {
        title: "7+ Years of Website Expertise",
        text: "With over 7 years of experience in the web design industry, our team brings a wealth of knowledge and expertise to each project we undertake.",
      },
      {
        title: "50+ Industries Served",
        text: "With experience across 50+ industries, we offer custom website design services by understanding various sectors' specific challenges and opportunities.",
      },
      {
        title: "200+ Website Launched",
        text: "With over 200+ successful website launches, our website design agency has the experience to deliver high-performing, custom websites that drive results.",
      },
      {
        title: "97% Client Retention Rate",
        text: "Our exceptional client retention rate of 97% demonstrates the lasting value and results our professional website design services deliver for businesses.",
      },
    ],
  };
  const commonContent1 = {
    title: "Standout With Our Custom Website Design Agency",
    content: [
      {
        title: "Design Methodologies",
        text: "We leverage the latest design trends and cutting-edge methodologies to create visually captivating, user-centric websites to help you stand out in the industry.",
      },
      {
        title: "Customized Solutions",
        text: "As the best website design agency, we specialize in crafting fully customized solutions aligning with your brand and audience goals.",
      },
      {
        title: "Responsive Designs",
        text: "Our professional website design services ensure seamless user experiences across all devices, maximizing online visibility and engagement.",
      },
      {
        title: "Conversion-Driven Layouts",
        text: "We incorporate strategic user flows and conversion-focused design elements to drive measurable results, such as increased lead generation and sales.",
      },
      {
        title: "Enhanced Accessibility",
        text: "Our website design services prioritize accessibility, ensuring your online presence is inclusive and accommodates users with diverse needs and abilities.",
      },
      {
        title: "Scalable Website Architecture",
        text: "Our website design company builds scalable and flexible website architectures, allowing for easy expansions and updates to accommodate your growing business needs.",
      },
    ],
  };
  const Questions = [
    "What website design services do you offer?",
    "Do you focus on SEO-friendly website design?",
    "Why should I choose Octet for professional website design?",
    "What is the timeline for crafting a creative website design?"
  ];
  const faqData = [
    {
        list: [
            `<span><h4 class="inline">Custom Website Design:</h4></span> We specialize in creating unique, custom-made websites that reflect your brand identity and relate with your target audience for a memorable online presence.`,
            `<span><h4 class="inline">Responsive Website Design:</h4></span> Our designs are fully responsive and adapt to all screen sizes and devices, providing an optimal viewing experience to users on different devices.`,
            `<span><h4 class="inline">E-commerce Website Design:</h4></span> We create visually appealing and user-friendly e-commerce websites that facilitate smooth navigation and seamless transactions, ultimately driving conversions for your online store.`,
            `<span><h4 class="inline">UI UX Design:</h4></span> Our focus on UI UX design ensures intuitive navigation, engaging interactions, and visually appealing layouts, enhancing overall user satisfaction and retention.`,
            `<span><h4 class="inline">Website Redesign:</h4></span> We also offer redesign services to refresh the look of your existing website, improve functionality, and enhance user experience, keeping it aligned with current design trends`,
            `<span><h4 class="inline">Website Maintenance:</h4></span> We provide ongoing support and maintenance services to keep your website secure and up-to-date, ensuring it performs optimally and delivers a seamless user experience over time.`
        ]
    },
    {
      para: [
        `Our website design company prioritizes SEO-friendly design to ensure that your website ranks well in search engine results, driving organic traffic and maximizing your online visibility.`,
        `From clean code to optimized content and site structure, we implement various strategies to enhance your website's SEO performance.`
      ],
    },
    {
      para: [
        `At our website design agency, you get to partner with a team of experienced designers and developers dedicated to delivering exceptional results.`,
        `We combine creativity, technical expertise, and strategic thinking to create custom websites that align with your brand, engage your audience, and drive measurable results for your business.`
      ],
    },
    {
      para: [
        `The timeline for creating a custom website design can vary depending on the complexity of the project and your specific requirements.`,
        `Our process involves initial consultations, design mockups, revisions, development, and testing phases, which can take 4 to 8 weeks`,
        `We prioritize quality and ensure that each step is executed meticulously to deliver a final product that exceeds your expectations.`,
        `To discuss the timeline of your website design project, please <a href='/contact-us/' title="contact us">contact us</a>.`
      ],
    },
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
         {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What website design services do you offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Custom Website Design
          We specialize in creating unique, custom-made websites that reflect your brand identity and relate with your target audience for a memorable online presence.
          Responsive Website Design
          Our designs are fully responsive and adapt to all screen sizes and devices, providing an optimal viewing experience to users on different devices.
          E-commerce Website Design
          We create visually appealing and user-friendly e-commerce websites that facilitate smooth navigation and seamless transactions, ultimately driving conversions for your online store.
          UI UX Design
          Our focus on UI UX design ensures intuitive navigation, engaging interactions, and visually appealing layouts, enhancing overall user satisfaction and retention.
          Website Redesign
          We also offer redesign services to refresh the look of your existing website, improve functionality, and enhance user experience, keeping it aligned with current design trends.
          Website Maintenance
          We provide ongoing support and maintenance services to keep your website secure and up-to-date, ensuring it performs optimally and delivers a seamless user experience over time."
              }
            },{
              "@type": "Question",
              "name": "Do you focus on SEO-friendly website design?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, our website design company prioritizes SEO-friendly design to ensure that your website ranks well in search engine results, driving organic traffic and maximizing your online visibility. 
          
          From clean code to optimized content and site structure, we implement various strategies to enhance your website's SEO performance."
              }
            },{
              "@type": "Question",
              "name": "Why should I choose Octet for professional website design?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our website design agency, you get to partner with a team of experienced designers and developers who are dedicated to delivering exceptional results. 
          We combine creativity, technical expertise, and strategic thinking to create custom websites that align with your brand, engage your audience, and drive measurable results for your business."
              }
            },{
              "@type": "Question",
              "name": "What is the timeline for crafting a creative website design?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The timeline for creating a custom website design can vary depending on the complexity of the project and your specific requirements. 
          
          Our process involves initial consultations, design mockups, revisions, development, and testing phases, which can take anywhere from 4 to 8 weeks. 
          
          We prioritize quality and ensure that each step is executed meticulously to deliver a final product that exceeds your expectations.
          
          To discuss the timeline of your website design project, please contact us."
              }
            }]
          }                                           
        `}
      </script>
    </Helmet>
    <Layout hire="Website Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Website Design Agency" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Website Design Agency | Creative Website Design"
    description="Our website design agency specializes in creating user-friendly websites. With our website design services, you can stand out online and grow your business."
  />
);
